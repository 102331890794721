const defaultState = {
    _id: "",
    name : "",
    studentID: "",
    parentName: "",
    email: "",
    contract: "",
    tel: "",
    startDate: "",
    dateOfBirth: "",
    homingConnect: false,
    isLoading: false,
    discordName: "",
    recomendSum: "",
    studentsList: [],
    parent: "",
    groups: [],
    filials: [],
    balanceSum: "",
    isActive: true,
    paymentLink: "",
    payToYesProg: false,
    lastNotification: {
        date: "",
        _id: ""
    }
}

export const studentReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "SET_STUDENT_DATA":
            return {
                ...state, ...action.payload
            }
        case "SET_STUDENT_LOADING":
            return {...state, isLoading: action.payload}

        case "SET_STUDENTS_LIST":
            return {...state, studentsList: action.payload}
        
        case "UPDATE_STUDENT_IN_LIST":
            return {...state, studentsList: state.studentsList.map(el=> {
                if (action.payload.id === el._id) {
                    return {...el, [action.payload.field]: action.payload.value}
                }
                return el
            })}

        case "DELETE_STUDENT_FROM_LIST":
            return {...state, studentsList: state.studentsList.filter(el=>el._id !== action.payload)}
    
        default:
            return state
    }
}