import $api from '../service/api-service.js';
import createCurMount from '../service/monthCreator.js';

const defaultState = {
    month: createCurMount(),
    year: new Date().getFullYear(),
    lessonsList: [],
    oneTimesList: [],
    onlyUnchecked: false,
    isLoading: false,
    confirmArchiveModalId: null
}

export const lessonsReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "SET_MONTH":
            return {...state, month: action.payload}
        case "SET_YEAR":
            return {...state, year: action.payload}
        case "SET_CONFIRM_ARCHIVEID":
            return {...state, confirmArchiveModalId: action.payload}
        case "CHANGE_CHECK_STATUS":
            return {...state, onlyUnchecked: action.payload}
        case "SET_LESSONS_LIST":
            return {...state, lessonsList: !state.onlyUnchecked ? 
                    action.payload :
                    action.payload?.filter((el)=>{
                            return el.lessons.filter(lesson => !lesson.isChecked).length > 0})}
        case "SET_LOADING":
            return {...state, isLoading: action.payload}
        case "REMOVE_FROM_LESSONS_LIST":
            return {...state, lessonsList: state.lessonsList.filter(el=>el._id !== action.payload)}
        case "SET_ONETIME_LIST":
            return {...state, oneTimesList: action.payload}
        case "ADD_ONETIME_LIST":
            return {...state, oneTimesList: [...state.oneTimesList, action.payload]}
        case "PULL_ONETIME_LIST":
            return {...state, oneTimesList: state.oneTimesList.filter(el=>el._id !== action.payload)}

        default:
            return state
    }
}

export const confirmArchiveModalIdSelector = state => state.lessons.confirmArchiveModalId

export const setConfirmArchiveGroupId = (groupId) => {
    return (dispatch) => {
        dispatch({
            type: "SET_CONFIRM_ARCHIVEID",
            payload: groupId , 
        });
    }
}

export const archiveGropHandler = () => {
    return async (dispatch, getState) => {
        const state = getState();
        const arciveddId = state.lessons.confirmArchiveModalId;

        await $api.post('/group/archive', 
            {groupID: arciveddId}
        )

        dispatch({type: "REMOVE_FROM_LESSONS_LIST", payload: arciveddId})

        dispatch({
            type: "SET_CONFIRM_ARCHIVEID",
            payload: null , 
        });
    }
}